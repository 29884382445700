@import './variables.scss';

@font-face { font-family: CeraPro-Regular; font-weight: 400; src: url('../fonts/CeraPro-Regular.woff');}
@font-face { font-family: CeraPro-Medium; font-weight: 400;src: url('../fonts/CeraPro-Medium.woff'); } 
@font-face { font-family: CeraPro-Bold; font-weight: bold; src: url('../fonts/CeraPro-Bold.woff');}
@font-face { font-family: P22Mackinac-Bold; font-weight: bold; src: url('../fonts/P22Mackinac-Bold_23.otf'); } 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f1eb;
  overflow-x: hidden;
  overflow-y: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b{
	font-family: CeraPro-Bold;
}

*{

	box-sizing: border-box;
}

button:focus{
  outline: none;
}

.img-miiiisection-1{
  width: 80%;
}